




























































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import PayrollService from "@/services/PayrollService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PayrollFileDTO from "@/dto/payroll/PayrollFileDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import FileService from "@/services/FileService";
import FileUpload from "@/components/common/FileUpload.vue";
import PersonService from "@/services/PersonService";
import PersonDTO from "@/dto/person/PersonDTO";
import SavePaystubRequestDTO from "@/dto/payroll/SavePaystubRequestDTO";
import PayrollFileCategories from "@/constants/PayrollFileCategories";
import RouteNames from "@/router/RouteNames";
import PayrollDocumentActionModal from "@/components/util/DocumentActionModal.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  components: {FileUpload}
})
export default class BusinessEmployeePaystubs extends Vue {

  @AppModule.State
  private loading!: boolean;

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Auth.Getter("hasAdministrativePrivileges")
  private hasAdministrativePrivileges!: boolean;

  private message = "";

  private successful = true;

  private paystubs: Array<PayrollFileDTO> = [];

  private employee = new PersonDTO(); // mock to prevent continuous null checking

  private filter: {
    period: string | null;
  } = {
    period: ""
  };

  mounted() {
    if (!this.$route.params.personId) {
      this.$router.push({
        name: RouteNames.PAYROLL_BUSINESS_EMPLOYEES,
        params: {companyId: this.$route.params.companyId, then: PayrollFileCategories.PAYSTUB}
      });
    } else {
      this.loadPerson().then(
          () => this.loadPaystubs()
      );
    }
  }

  loadPerson() {
    if (!this.hasAdministrativePrivileges) {
      return Promise.resolve();
    }
    this.startLoading();
    return PersonService.getEmployee(this.getEmployeeId, this.getEmployerId).then(
        response => {
          this.successful = true;
          this.stopLoading();
          this.employee = response.data;
        },
        error => {
          this.stopLoading();
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        }
    );
  }

  loadPaystubs() {
    this.startLoading();
    return PayrollService.getPayrollFiles(
        PayrollFileCategories.PAYSTUB,
        this.getEmployeeId,
        this.getEmployerId,
        this.filter.period).then(
        (response) => {
          this.successful = true;
          this.paystubs = response.data;
          this.stopLoading()
        },
        (error) => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading()
        }
    );
  }

  onDocumentClick(document: PayrollFileDTO) {
    if (this.hasAdministrativePrivileges) {
      this.showActions(document);
    } else {
        Vue.prototype.$docUtils.download(document.file.id);
    }
  }

  showActions(document: PayrollFileDTO) {
    this.$modal.show(
        PayrollDocumentActionModal,
        {
          onDelete: () => this.deleteDocument(document),
          onDownload: () => Vue.prototype.$docUtils.download(document.file.id)
        },
        {width: "50%", height: "auto"}
    );
  }

  deleteDocument(document: PayrollFileDTO) {
    this.message = "";
    this.startLoading();
    PayrollService.deleteDocument(document.id, 'PAYROLL_FILE').then(
        response => {
          this.message = "Deleted";
          this.successful = true;
          this.stopLoading();
          this.loadPaystubs();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  onFilesUploaded(files: FileMetaDTO[]) {
    files.forEach(f => {
      PayrollService.savePaystub(new SavePaystubRequestDTO(this.employee.id as number, f, PayrollFileCategories.PAYSTUB)).then(
          response => {
            this.paystubs.push(response.data);
          }
      );
    });
  }

  get getEmployerId() {
    return Number.parseInt(this.$route.params.companyId, 10);
  }

  get getEmployeeId() {
    return Number.parseInt(this.$route.params.personId, 10);
  }

  clearFilter() {
    this.filter.period = null;
  }

}
